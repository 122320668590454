import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Sidbar Permissions

import Sidebar from "./permissions/sidebar/reducer";

// Modules Permissions
import Module from "./permissions/modules/reducer";

import TabChange from "./utils/TabChange/reducer";
import ToggleModal from "./utils/ConfirmationModal/reducer";
import SharedData from "./utils/SharedData/reducer";
import Notifications from "./general/notifications/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Sidebar,
  Module,
  TabChange,
  ToggleModal,
  Notifications,
  SharedData,
});

export default rootReducer;

import React, { lazy } from "react";

// Users

const Users = lazy(() => import("pages/Settings/Users"));
const UsersForm = lazy(() => import("pages/Settings/Users/usersForm"));
// Companies
// Taxes
const Logs = lazy(() => import("pages/Settings/Logs"));
const UsersActivity = lazy(() => import("pages/Settings/UsersActivity"));

// desctructure the paths

export const settingsRoutes = [
  // users
  { path: "/users", component: Users },
  // Add New User
  { path: "/users/add", component: UsersForm },
  { path: "/users/edit/:id", component: UsersForm },

  // logs
  { path: "/logs/:moduleId/:resourceId", component: Logs },
  // user activites
  { path: "/users/:resourceId/activity", component: UsersActivity },
];

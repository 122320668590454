import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { Link } from "react-router-dom"
import { convertNameToRoute } from "utils"
import { feesByBeneficiaryReportModuleId } from "utils/modulesIds"

const SidebarItem = ({ item }) => {
  return (
    <Link
      to={`/${convertNameToRoute(item.name)}`}
      data-toggle="tooltip"
      title={item.title}
    >
      <i className={item.icon ? item.icon : "bx bxs-camera-off"}></i>
      <span>{item.title}</span>
    </Link>
  )
}

SidebarItem.propTypes = {
  item: PropTypes.object,
}
export default SidebarItem

/*
return item.id === feesByBeneficiaryReportModuleId ? (
    <Fragment>
      <Link
        to={`/${convertNameToRoute(item.name)}`}
        data-toggle="tooltip"
        title={item.title}
        className="has-arrow"
      >
        <i className={item.icon ? item.icon : "bx bxs-camera-off"}></i>
        <span>{item.title}</span>
      </Link>
      <ul className="sub-menu">
        <li>
          <Link to="/crypto-wallet">Wallet</Link>
        </li>
        <li>
          <Link to="/crypto-buy-sell">Buy/Sell</Link>
        </li>
        <li>
          <Link to="/crypto-exchange">Exchange</Link>
        </li>
        <li>
          <Link to="/crypto-lending">Lending</Link>
        </li>
      </ul>
    </Fragment>
  ) : (
    <Link
      to={`/${convertNameToRoute(item.name)}`}
      data-toggle="tooltip"
      title={item.title}
    >
      <i className={item.icon ? item.icon : "bx bxs-camera-off"}></i>
      <span>{item.title}</span>
    </Link>
  )
*/

import { request } from "../api_helper"

// export const generalRequest = ({
//   page,
//   per_page,
//   sort_by,
//   sort_type,
//   search_keys,
//   search_values,
//   search_value,
//   view,
//   url,
//   ...rest
// }) =>
//   request({
//     url,
//     method: "GET",
//     params: {
//       page: page ? page : 1,
//       paginate: per_page ? per_page : 10,
//       sort_by: sort_by ? sort_by : "id",
//       sort_type,
//       search_keys,
//       search_values,
//       search_value,
//       view,
//       ...rest,
//     },
//   })

export const generalRequest = ({ url, ...rest }) =>
  request({
    url,
    method: "GET",
    params: {
      ...rest,
    },
  })

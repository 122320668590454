import { get, request } from "../api_helper"

import * as url from "../url_helper"

// get all modules for the logged in user
// url
// export const getAllModules = () => get(url.USER_MODULES)

export const getAllModules = params =>
  request({
    url: url.USER_MODULES,
    method: "GET",
    params,
  })

// get all actions that allowed to the logged in user on a specific module
//url/{id}
export const getAllActions = moduleId =>
  get(`${url.USER_MODULES}/${moduleId}`, moduleId)

// get all Submodules and Ipnuts that allowed to the logged in user on a specific module
//url/{id}

// export const getAllSubModules = moduleId =>
//   get(`${url.MODULES_SUBMODULES}/${moduleId}`, moduleId)

export const getAllSubModules = (moduleId, params) =>
  request({
    url: `${url.MODULES_SUBMODULES}/${moduleId}`,
    method: "GET",
    params,
  })

import { request } from "../api_helper"
import * as url from "../url_helper"
/*
Networks
*/

// add new network

// url
export const addNetwork = data =>
  request({ url: url.NETWORKS, method: "POST", data })

// get all healthCares with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllNetworks = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  ...rest
}) =>
  request({
    url: url.NETWORKS,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
  })

// network Exlusions

export const getNetworkExclusions = (networkId, params) =>
  request({
    url: `${url.NETWORKS}/${networkId}/exclusions`,
    method: "GET",
    params,
  })
// show network
//url/{id}

export const showNetwork = networkId =>
  request({ url: `${url.NETWORKS}/${networkId}`, method: "GET" })

// update network
//url/{id}

export const updateNetwork = data =>
  request({ url: `${url.NETWORKS}/${data.id}`, method: "PUT", data })

// delete network
//url/{id}
export const deleteNetwork = networkId =>
  request({
    url: `${url.NETWORKS}/${networkId}`,
    method: "DELETE",
  })

// activate network
//url/activate/{id}
export const activateNetwork = networkId =>
  request({
    url: `${url.NETWORKS}/${networkId}/activate`,
    method: "POST",
  })

// deactivate network
//url/deactivate/{id}
export const deactivateNetwork = networkId =>
  request({
    url: `${url.NETWORKS}/${networkId}/deactivate`,
    method: "POST",
  })

// copy network
//url/copy/{id}
export const copyNetwork = data =>
  request({
    url: `${url.NETWORKS}/${data.id}/copy`,
    method: "POST",
    data,
  })

import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// import images
import logodark from "assets/images/logo-dark.png";
import logolight from "assets/images/logo-light.png";
// actions
import { loginUser } from "../../store/actions";

import { showCompany } from "helpers/Settings/Company";
import {
  NotificationMessage,
  apiErrorrHandler,
  decryptText,
  encryptText,
  isInputInvalid,
} from "utils";
import CarouselPage from "./CarouselPage";
import { showHealthCareProvider } from "helpers/Stakeholders/healthCare";
import { getAllBenefits } from "helpers/CommonData/benefits";
import { FormControll } from "components/TP/Common/Forms";
import CustomButton from "components/TP/Common/General/CustomButton";

const Login = (props) => {
  //meta title
  document.title = "Login";

  const dispatch = useDispatch();
  // states

  const [passwordShow, setPasswordShow] = useState(false);

  // Calling for view company endpoint
  // to get the currency from it after login
  const getCompany = async () => {
    try {
      const proivderID = JSON.parse(
        decryptText(localStorage.getItem("mainProvider"))
      );
      const response = await showCompany();
      const providerRes = await getAllBenefits({
        per_page: 1000,
        provider_type_id: proivderID?.type?.id,
        has_slug: "1",
        class_allowed: 1,
        is_active: 1,
      });

      const providerBenefits = providerRes?.items;
      localStorage.setItem(
        "providerBenefits",
        encryptText(JSON.stringify(providerBenefits))
      );

      const mainCurrency = response?.item?.main_currency;
      localStorage.setItem(
        "TPACurrency",
        encryptText(JSON.stringify(mainCurrency))
      );
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history, () => getCompany()));
    },
  });

  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    getFieldProps,
  } = validation;

  const { error, loading } = useSelector((state) => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }));

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p className="text-muted">Sign in to continue.</p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                          }}
                        >
                          {/* Error Message */}
                          {error ? (
                            <Alert color="danger">
                              {typeof error === "object" && error !== null
                                ? "Incorrect Username or Password"
                                : error}
                            </Alert>
                          ) : null}
                          {/* User Name Input */}
                          <FormControll
                            id="485"
                            name="username"
                            type="text"
                            label="Username"
                            placeholder="Enter Username"
                            {...getFieldProps("username")}
                            invalid={isInputInvalid(validation, "username")}
                            error={errors.username}
                            wrapperClassNames="mb-3"
                          />

                          {/* password */}
                          <div className="mb-3">
                            <div className="float-end">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                Forgot password?
                              </Link>
                            </div>

                            <FormControll
                              id="485"
                              name="password"
                              type="password"
                              label="Password"
                              placeholder="Enter Password"
                              {...getFieldProps("password")}
                              invalid={isInputInvalid(validation, "password")}
                              error={errors.password}
                            />
                          </div>

                          <div className="mt-3 d-grid">
                            <CustomButton
                              className="btn btn-primary btn-block"
                              type="submit"
                              disabled={loading}
                              isSaving={loading}
                              title="Log In"
                            />
                          </div>
                        </Form>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p>© {new Date().getFullYear()}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Login));

Login.propTypes = {
  history: PropTypes.object,
};

/* Paths */
import { reportPaths } from "common/data/routePaths";
import { lazy } from "react";
/*
Components
*/

// claim accounting report
const AccountingReport = lazy(() => import("pages/Reports/AccountingReport"));

const { accounting } = reportPaths;

export const ReportsRoutes = [
  // claim accounting report
  {
    path: accounting,
    component: AccountingReport,
  },
];

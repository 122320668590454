import React, { Fragment, useEffect, useRef, useState } from "react"
import {
  DatePickerInput,
  FormControll,
  FormModal,
} from "components/TP/Common/Forms"
// translation
import { withTranslation } from "react-i18next"
// helper functions

import {
  calculatePremiums,
  getAllBeneficaries,
  savePremiums,
} from "helpers/Production/Policies/beneficaries_helper"
import { NotificationMessage, apiErrorrHandler } from "utils"
import { TableSkeleton } from "components/Common"
import { Alert, Col, Row } from "reactstrap"
import { isInteger, isNaN, isNumber, uniqBy } from "lodash"
import { formatDate, formatedTodayData } from "utils/smallUtils"
import MultiSkeleton from "../Common/MultiSkeleton"
import { stakeholderPaths } from "common/data/routePaths"
import { useHistory } from "react-router-dom"
import { beneficaryinstallmentsId } from "utils/modulesIds"
import { buttonsTitles, modalHeaders, staticFieldLabels } from "common/data"
import { StickyHeaderTable } from "../Common/Tables"

const PremiumValidationModal = ({ isModal, setIsModal, beneficiary, t }) => {
  const history = useHistory()
  const [error, setError] = useState("")
  const [discountError, setDiscountError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isCalculating, setIsCalculating] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [beneficiaries, setBeneficiaries] = useState([])
  const [beneficiariesIds, setBeneficiariesIds] = useState([])

  let newBeneficiaries = []

  const [editableData, setEditableData] = useState({
    discount_percentage: 0,
    discount_amount: 0,
    adjustment_amount: 0,
    issuing_fees: 0,
  })

  const [tableHeader, setTableHeader] = useState([
    "#",
    "ID",
    t("Beneficiary"),
    t("Relation"),
    t("DOB"),
    t("Net Premium"),
    t("Gross Premium"),
  ])
  // const [effectiveDate, setEffectiveDate] = useState(formatedTodayData())
  const [effectiveDate, setEffectiveDate] = useState(
    beneficiary?.policy && beneficiary?.policy?.start_date
      ? beneficiary?.policy.start_date
      : formatedTodayData()
  )

  // round float numbers
  const roundNumber = number => {
    if (isInteger(number)) {
      return number
    } else {
      return parseFloat(number)
    }
  }

  // beneficiaries reducer (for net Premium and net groos)
  const beneficiariesReducer = name => {
    let reducedValue = 0
    if (name === "total_net") {
      reducedValue = parseFloat(
        beneficiaries.reduce(
          (total, item) => (item.price ? total + item.price : total + 0),
          0
        )
      )
    } else if (name === "total_gross") {
      reducedValue = parseFloat(
        beneficiaries.reduce(
          (total, item) =>
            item.total_price ? total + item.total_price : total + 0,
          0
        )
      )
    }
    // else if (name === "maxDiscount") {
    //   reducedValue = +beneficiaries.reduce(
    //     (total, item) => total + item.loading_amount + item.commission_amount,
    //     0
    //   )
    // }
    else if (name === "maxDiscount") {
      reducedValue = +beneficiaries.reduce(
        (total, item) => total + item.loading_amount,
        0
      )
    } else if (name === "vat") {
      reducedValue = +beneficiaries.reduce(
        (total, item) => total + item.tax_amount,
        0
      )
    } else {
      reducedValue = 0
    }
    return isNaN(reducedValue) ? 0 : roundNumber(reducedValue)
  }

  // Get ths SubBeneficiaries of the this beneficiary
  const getSubBeneficiaries = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
    parent_id,
    ...rest
  }) => {
    try {
      setIsLoading(true)
      const res = await getAllBeneficaries({
        page,
        per_page,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        parent_id,
        ...rest,
      })
      // Store the IDs of subBeneficiary related to that parent beneficiary
      const subIDs = res.items.length > 0 ? res.items.map(item => item.id) : []

      setBeneficiariesIds([beneficiary.id, ...subIDs])

      setBeneficiaries([{ ...beneficiary }, ...res.items])
      // setBeneficiaries(prev => {
      //   return [...prev, ...res.items]
      // })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  // calculate Premium

  const calculateBeneficiaryPremiums = async beneficaryId => {
    setIsCalculating(`${beneficaryId}`)
    try {
      const response = await calculatePremiums({
        discount_amount: editableData.discount_amount,
        adjustment_amount: editableData.adjustment_amount,
        issuing_fees: editableData.issuing_fees,
        beneficaryId: beneficaryId,
        effective_date: effectiveDate,
      })
      const { policy, beneficiary: resBenef, ...others } = response.item

      const getDOB = beneficiaries.filter(item => item.id === resBenef.id)
      newBeneficiaries.push({
        ...resBenef,
        birth_date: getDOB[0].birth_date,
        ...others,
      })

      NotificationMessage("success", "Calculated Successfully")
      setError("")
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      setError(errorMessage)
    } finally {
      setIsCalculating(false)
      setBeneficiaries(prev => {
        return uniqBy([...newBeneficiaries, ...prev], "id")
      })
    }
  }

  const handleSavePremiums = async () => {
    setIsSaving(true)
    try {
      const response = await savePremiums({
        beneficiaries: beneficiariesIds,
        discount_amount: editableData.discount_amount || 0,
        adjustment_amount: editableData.adjustment_amount || 0,
        effective_date: effectiveDate ? effectiveDate : formatedTodayData(),
        issuing_fees: editableData.issuing_fees || 0,
        policy_id: beneficiary?.policy?.id,
      })
      NotificationMessage("success", t("Saved Successfully"))
      setIsModal(false)
      setTimeout(() => {
        window.location.reload()
      }, 2000)
      setError("")
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      setError(errorMessage)
    } finally {
      setIsSaving(false)
    }
  }

  // get Subbeneficiaries of beneficiary id that passed from beneficiary list table
  useEffect(() => {
    if (beneficiary.id) {
      getSubBeneficiaries({ parent_id: beneficiary.id })
    }
  }, [beneficiary.id])

  const calculateAllPremiums = () => {
    if (beneficiariesIds.length > 0) {
      beneficiariesIds.forEach((item, index) => {
        setTimeout(() => {
          calculateBeneficiaryPremiums(item)
        }, index * 1000)
      })
    }
  }

  useEffect(() => {
    calculateAllPremiums()
  }, [beneficiariesIds.length])

  // for handling the three inputs under the table change
  // disocunt_amout,discount_percentage and adjustment_amount
  const handleChangeInputs = e => {
    // if user enters a sign in fron of the number remove it with RegEx
    const name = e.target["name"]
    const value = e.target["value"].replaceAll("-", "")

    setEditableData(prev => {
      return name === "discount_amount"
        ? {
            ...prev,
            [name]: value,
            discount_percentage: roundNumber(
              (value / beneficiariesReducer("total_gross")) * 100
            ),
          }
        : name === "discount_percentage"
        ? {
            ...prev,
            [name]: value,
            discount_amount: roundNumber(
              (value * beneficiariesReducer("total_gross")) / 100
            ),
          }
        : { ...prev, [name]: value ? parseFloat(value) : 0 }
    })
  }

  const generateLabelWithCurrency = labelTitle => {
    return `${labelTitle} ${beneficiary?.policy?.currency?.symbol}`
  }
  return (
    <FormModal
      show={isModal}
      onCloseClick={() => {
        setIsModal(false)
        setError("")
      }}
      onSaveClick={() => {
        handleSavePremiums()
      }}
      includeComputeButton={true}
      onComputeClick={() => calculateAllPremiums()}
      hideSaveButton={beneficiary?.policy?.is_active ? false : true}
      modalTitle={modalHeaders.premiumValidation}
      // isSaving={editable ? isSaving || discountError : true}
      saveButtonTitle={buttonsTitles.savePremium}
      saveButtonBG="primary"
      isSaving={
        isSaving ||
        isCalculating ||
        editableData.discount_amount > beneficiariesReducer("maxDiscount")
          ? true
          : false
      }
      modalSize="xl"
      // noFooter={false}
      notCentered={true}
    >
      {error && <Alert color="danger">{error}</Alert>}
      {beneficiary?.policy?.is_active ? (
        <DatePickerInput
          label={staticFieldLabels.effectiveData}
          value={effectiveDate}
          onChange={(date, dateStr, instance) => setEffectiveDate(dateStr)}
          onBlur={() => console.log("blured")}
          // disableFutureDates={false}
          removeDisable={true}
          // minDate={policy?.start_date}
          // maxDate={policy?.stop_date}
          minDate={beneficiary?.policy?.start_date}
          maxDate={beneficiary?.policy?.stop_date}
        />
      ) : null}
      {/* Table */}

      <StickyHeaderTable
        columns={tableHeader}
        noData={beneficiaries.length === 0 ? true : false}
        isLoading={isLoading}
      >
        {beneficiaries.length > 0 &&
          beneficiaries.map((beneficiary, index) => (
            <tr key={beneficiary.id}>
              <td>{index + 1}</td>
              {/* ID */}
              <td>{beneficiary.id}</td>
              {/* beneficiary name */}
              <td>{beneficiary?.name}</td>
              {/* beneficiary Dependency */}
              <td>{beneficiary?.dependency?.name}</td>
              {/* beneificary DOB */}
              <td>{formatDate(beneficiary?.birth_date)}</td>
              {/* gross Premium */}
              {isCalculating === `${beneficiary.id}` ? (
                <Fragment>
                  <td>
                    <MultiSkeleton length={1} />
                  </td>
                  <td>
                    <MultiSkeleton length={1} />
                  </td>
                </Fragment>
              ) : (
                <Fragment>
                  {/* net Premium */}
                  <td>
                    {beneficiary?.price >= 0
                      ? roundNumber(beneficiary?.price)
                      : ""}
                  </td>
                  {/* gross Premium */}
                  <td>
                    {beneficiary?.total_price >= 0
                      ? roundNumber(beneficiary?.total_price)
                      : ""}
                  </td>
                </Fragment>
              )}
            </tr>
          ))}
      </StickyHeaderTable>

      {/* Calculations inputs */}
      <Row className="mt-3">
        <Fragment>
          <Col md={2}>
            <FormControll
              id="1"
              name="total_net"
              type="number"
              label={generateLabelWithCurrency(staticFieldLabels.totalNet)}
              value={beneficiariesReducer("total_net")}
              readOnly={true}
              wrapperClassNames="mb-3"
            />
          </Col>
          <Col md={2}>
            <FormControll
              id="2"
              name="total_gross"
              type="number"
              label={generateLabelWithCurrency(staticFieldLabels.totalGross)}
              value={beneficiariesReducer("total_gross")}
              readOnly={true}
              wrapperClassNames="mb-3"
            />
          </Col>
          <Col md={2}>
            <FormControll
              id="3"
              name="discount_percentage"
              type="number"
              label={generateLabelWithCurrency(staticFieldLabels.discount)}
              value={editableData.discount_percentage}
              onChange={e => handleChangeInputs(e)}
              wrapperClassNames="mb-3"
            />
          </Col>
          <Col md={2}>
            <FormControll
              id="4"
              name="discount_amount"
              type="number"
              label={generateLabelWithCurrency(
                staticFieldLabels.discountAmount
              )}
              max={beneficiariesReducer("total_gross")}
              value={editableData.discount_amount}
              onChange={e => handleChangeInputs(e)}
              wrapperClassNames="mb-3"
              error={
                editableData.discount_amount >
                  beneficiariesReducer("maxDiscount") &&
                `Must be ${beneficiariesReducer("maxDiscount")} Max`
              }
              invalid={
                editableData.discount_amount >
                beneficiariesReducer("maxDiscount")
                  ? true
                  : false
              }
            />
          </Col>
          {/* Vat amount */}
          <Col md={2}>
            <FormControll
              id="100"
              name="vat_amount"
              type="number"
              label={generateLabelWithCurrency(staticFieldLabels.vatAmount)}
              value={beneficiariesReducer("vat")}
              readOnly={true}
              wrapperClassNames="mb-3"
            />
          </Col>
          {/* Total Premiums */}
          <Col md={2}>
            <FormControll
              id="4"
              name="total_amount"
              type="number"
              label={generateLabelWithCurrency(staticFieldLabels.totalPremiums)}
              value={
                beneficiariesReducer("total_gross") +
                beneficiariesReducer("vat")
              }
              readOnly={true}
              wrapperClassNames="mb-3"
            />
          </Col>
          <Col md={2}>
            <FormControll
              id="5"
              name="issuing_fees"
              type="number"
              label={generateLabelWithCurrency(staticFieldLabels.issuingFees)}
              value={editableData.issuing_fees}
              onChange={e => handleChangeInputs(e)}
              wrapperClassNames="mb-3"
            />
          </Col>
          <Col md={2}>
            <FormControll
              id="6"
              name="adjustment_amount"
              type="number"
              label={generateLabelWithCurrency(staticFieldLabels.overPremium)}
              value={editableData.adjustment_amount}
              onChange={e => handleChangeInputs(e)}
              wrapperClassNames="mb-3"
            />
          </Col>
          <Col md={2}>
            <FormControll
              id="7"
              name="sum_insured"
              type="number"
              label={generateLabelWithCurrency(staticFieldLabels.sumInsured)}
              // value={
              //   beneficiariesReducer("total_gross") +
              //   editableData.adjustment_amount +
              //   0 +
              //   editableData.issuing_fees -
              //   editableData.discount_amount
              // }
              value={
                beneficiariesReducer("total_gross") +
                beneficiariesReducer("vat") +
                editableData.adjustment_amount +
                editableData.issuing_fees
              }
              readOnly={true}
              wrapperClassNames="mb-3"
            />
          </Col>
        </Fragment>
      </Row>
    </FormModal>
  )
}

export default withTranslation()(PremiumValidationModal)

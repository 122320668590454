export const reportPaths = {
  dynamicReport: "/dynamic-report",
  blackListedReport: "/blacklisted-report",
  dueToExpirtyReport: "/policies-due-to-expire",
  agentComissionReport: "/agent-commission",
  notRenewedReports: "/policies-not-renewed",
  agentPoliciesReports: "/agent-policies",
  volumeRebateReport: "/provider-volume-rebate",
  feesByBeneficiaryReport: "/fees-by-beneficiary",
  feesByClaimsReport: "/fees-by-claims",
  premiumFees: "/premium-fees",
  lossRatio: "/loss-ratio",
  insurerScoreboard: "/insurere-scoreboard",
  priorityPayer: "/other-guarantor-bordereau",
  accounting: "/claim-accounting-report",
};

import moment from "moment"
import mapValues from "lodash/mapValues"
import parse from "html-react-parser"
import { previewDateFormat } from "constants/TPA"

import * as CryptoJS from "crypto-js"
import { lazy } from "react"
import {
  compact,
  get,
  isArray,
  isBoolean,
  isEmpty,
  isNumber,
  omit,
  remove,
  set,
  values,
} from "lodash"

// formatedd Today Date for Date Picker Input

export const formatedTodayData = () => {
  return moment().format("YYYY-MM-DD")
}
export const formatDate = date => {
  return date ? moment(date).format(previewDateFormat) : ""
}

// Convert Null Values in object to empty string
export function convertNullToString(object) {
  const newObject = mapValues(object, v => (v === null ? "" : v))
  return newObject
}

// Reset Column Filter in Table

export const resetSearchColumnInput = () => {
  const columnFilter = Array.from(document.querySelectorAll(".ColumnFilter"))

  columnFilter.length > 0 &&
    columnFilter.forEach(item => {
      console.log("=======", item.target)
      item.value = ""
    })
}

// Check if the item is object or not
export const isItemObject = item => {
  if (typeof item === "object" && item !== null) {
    return true
  } else {
    return false
  }
}

// find an input based on id from an array
export const fieldFinder = (inputsArray, id) => {
  const field =
    inputsArray &&
    inputsArray?.length > 0 &&
    inputsArray.find(item => item.id === parseInt(id))
  return field ? { ...field, label_name: parse(field?.label_name || "") } : null
}

// find an input based on id from an array
export const findInputDefaultValue = (inputsArray, id) => {
  const fieldType =
    inputsArray &&
    inputsArray.find(item => item.id === parseInt(id))?.field_type
  return inputsArray
    ? inputsArray.find(item => item.id === parseInt(id))?.default_value &&
      (fieldType === "dropdown".toLowerCase() ||
        fieldType === "number".toLowerCase() ||
        fieldType === "boolean".toLowerCase())
      ? parseInt(
          inputsArray.find(item => item.id === parseInt(id))?.default_value
        )
      : inputsArray.find(item => item.id === parseInt(id))?.default_value
    : ""
}

/**
 * Formats the size
 */
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
  // var newDate = new Date(date)

  var offset = date.getTimezoneOffset() / 60
  var hours = date.getHours()
  // console.log(offset)
  // console.log(hours)
  newDate.setHours(hours + offset)
  // newDate.setHours(hours - offset)

  return newDate
}

export function compareDates(minDate, maxDate, callback) {
  console.log("min", minDate)
  console.log("max", maxDate)
  if (minDate && maxDate && maxDate < minDate) {
    setTimeout(() => {
      callback()
    }, 500)
  }
}

export function convertNameToRoute(name) {
  return name.toLowerCase().replace(/\s/g, "-")
}

export function arrayReducer(array, name) {
  const reducedValue = Array.isArray(array)
    ? array.reduce(
        (total, item) => (name ? total + item[name] : total + item),
        0
      )
    : 0
  // return parseInt(reducedValue) === 0 ? 0 : (+reducedValue).toFixed(2)
  return isFloat(reducedValue) ? (+reducedValue).toFixed(2) : reducedValue
}

export function roundNumber(number) {
  return parseInt(number) === 0 || isNaN(number) || parseInt(number) < 0
    ? 0
    : (+number).toFixed(2)
}

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0
}

// encrypt message
export function encryptText(text) {
  // const encrypted = CryptoJS.AES.encrypt(text, "Secret").toString()
  // return encrypted

  var b64 = CryptoJS.AES.encrypt(text?.toString(), "SECRET").toString()
  var e64 = CryptoJS.enc.Base64.parse(b64)
  var eHex = e64.toString(CryptoJS.enc.Hex)
  return eHex
}
export function decryptText(cipher) {
  // const bytes = CryptoJS.AES.decrypt(cipher, "Secret")
  // const decrypted = bytes.toString(CryptoJS.enc.Utf8)
  // return decrypted
  var reb64 = CryptoJS.enc.Hex.parse(cipher)
  var bytes = reb64.toString(CryptoJS.enc.Base64)
  var decrypt = CryptoJS.AES.decrypt(bytes, "SECRET")
  var plain = decrypt.toString(CryptoJS.enc.Utf8)
  return plain
}

// mask the id with random characters
export function generateMaskedId(id) {
  return `${id}!$+${Math.floor(Math.random() * 100) * 99}`
}

// lazy load components

export function lazyLoad(path, namedExport) {
  return lazy(async () => {
    const promise = import(path)
    if (namedExport === null) {
      return promise
    } else {
      return promise.then(module => ({ default: module[namedExport] }))
    }
  })
}

export function objectToValue(object, value) {
  return isItemObject(object) ? object[value] : object
}

export function isObjectValuesEmpty(object) {
  return isEmpty(compact(values(object)))
  // return isEmpty(
  //   compact(
  //     values(object).filter(
  //       item => !isBoolean(item) && !isNumber(item)
  //     )
  //   )
  // )
  // return isEmpty(
  //   values(object).filter(item => !isBoolean(item) && !isNumber(item) && !item)
  // )
}

export function removeEmptyObject(arrayOfObjects) {
  const newArray =
    Array.isArray(arrayOfObjects) && arrayOfObjects.length > 0
      ? remove(
          arrayOfObjects.map(item => omit(item, ["id"])),
          function (item) {
            return !isObjectValuesEmpty(item)
          }
        )
      : []
  return newArray
}

// flatten objects

export function flattenObject(ob) {
  var toReturn = {}

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue

    if (typeof ob[i] == "object" && ob[i] !== null) {
      var flatObject = flattenObject(ob[i])
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue

        toReturn[i + "." + x] = flatObject[x]
      }
    }
    if (Array.isArray(ob[i]) && ob[i]?.length > 0) {
      ob[i].forEach(item => {
        if (typeof item == "object" && item !== null) {
          var flatObject = flattenObject(item)
          for (var x in flatObject) {
            if (!flatObject.hasOwnProperty(x)) continue

            toReturn[i + "." + x] = flatObject[x]
          }
        }
      })
    } else {
      toReturn[i] = ob[i]
    }
  }

  return toReturn
}

// get the only updated values between the initial values and values
export function updatedFields(initialVal, values) {
  const flattened = flattenObject(initialVal)
  //only get the modified values to not accidentally edit old ones.
  let resultObject = {}
  Object.entries(flattened)?.map(entry => {
    const [key, oldVal] = entry
    const newVal = get(values, key)
    if (newVal !== oldVal) {
      set(resultObject, key, newVal)
    }
  })
  return resultObject
}

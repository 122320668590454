import React, { Fragment, useState } from "react";
import MultiSkeleton from "../Common/MultiSkeleton";
import moment from "moment";
import { modalsStates } from "common/data";
import { StatusHistoryModal } from ".";

const TopBeneficiaryDetails = ({
  beneficiary,
  isLoading,
  claimStatus,
  reference,
  providerName,
  benefit,
  claim,
}) => {
  const [showModal, setShowModal] = useState("");

  return (
    <Fragment>
      <div className="mb-2">
        {isLoading ? (
          <MultiSkeleton length={1} />
        ) : (
          <ul className="list-unstyled hstack gap-2 flex-wrap">
            {/* Claims data */}
            <li className="d-flex gap-2 align-items-center">
              <i className="bx bx-shield-quarter font-size-14 text-primary"></i>
              {/* in update make it a hyber link to open statuses history popup */}
              {/* in add just a text*/}
              {reference ? (
                <button
                  type="button"
                  className="btn bg-transparent border-0 p-0 text-blue"
                  onClick={() => setShowModal(modalsStates.statusHistory)}
                >
                  {benefit?.slug} : {reference} {`{${claimStatus?.name}}`}
                </button>
              ) : (
                <span>
                  {benefit?.slug} : {`{${claimStatus?.name}}`}{" "}
                </span>
              )}
            </li>
            {/* Beneficiary Code */}
            <li className="d-flex gap-2 align-items-center">
              <i className="bx bx-id-card font-size-14 text-primary"></i>
              <span>{beneficiary?.code}</span>
            </li>
            {/* Data of Birth */}
            <li className="d-flex gap-2 align-items-center">
              <i className="bx bx-cake font-size-14 text-primary"></i>
              {beneficiary && beneficiary.birth_date ? (
                <span>
                  {beneficiary?.birth_date}
                  {` (${moment().diff(beneficiary.birth_date, "years")} Y)`}
                </span>
              ) : (
                <span>N/A</span>
              )}
            </li>
            {/* Policy Start Date to Policy End Date */}
            <li className="d-flex gap-2 align-items-center">
              <i className="bx bx-calendar font-size-14 text-primary"></i>
              <span>
                <b>Expiry</b>{" "}
                {beneficiary?.policy?.stop_date
                  ? moment(beneficiary?.policy?.stop_date).format("DD-MM-YYYY")
                  : "N/A"}
              </span>
            </li>
          </ul>
        )}
      </div>

      {showModal === modalsStates.statusHistory ? (
        <StatusHistoryModal
          show={showModal === modalsStates.statusHistory}
          statuses={claim?.statuses}
          onCloseClick={() => setShowModal("")}
          loading={false}
        />
      ) : null}
    </Fragment>
  );
};

export default TopBeneficiaryDetails;

import { claimPaths } from "common/data/routePaths";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { getModules as onGetModules } from "store/actions";
import {
  AccountingReportModuleId,
  claimBatchModuleId,
  claimFormModuleId,
  usersModuleID,
} from "utils/modulesIds";

const ShortcutMenu = () => {
  const [socialDrp, setsocialDrp] = useState(false);

  const dispatch = useDispatch();

  const { modules } = useSelector((state) => ({
    modules: state.Sidebar.modules,
  }));

  // getting all modules when the component did mount
  useEffect(() => {
    if (modules.length === 0) {
      dispatch(onGetModules());
    }
  }, [modules.length]);

  const shortcuts = [
    claimBatchModuleId,
    claimFormModuleId,
    AccountingReportModuleId,
    usersModuleID,
  ];
  return (
    <Dropdown
      className="d-none d-lg-inline-block ms-1"
      isOpen={socialDrp}
      toggle={() => {
        setsocialDrp(!socialDrp);
      }}
    >
      {/* Shortcuts Menu Toggler */}

      <DropdownToggle className="btn header-item noti-icon " tag="button">
        <i className="bx bx-customize" />
      </DropdownToggle>

      <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
        <div className="px-lg-2">
          <Row className="no-gutters">
            {modules.length > 0 &&
              modules.map((module) => (
                <Fragment key={module.id}>
                  {module.sub_modules.length > 0 &&
                    module.sub_modules.map(
                      (sub_module) =>
                        shortcuts.includes(sub_module.id) && (
                          <Col key={sub_module.id}>
                            <Link
                              className="dropdown-icon-item"
                              style={{ width: "140px" }}
                              to={`/${sub_module.name
                                .toLowerCase()
                                .replace(" ", "-")}`}
                            >
                              <i
                                className={`${sub_module.icon} font-size-24`}
                              ></i>
                              <span>{sub_module.title}</span>
                            </Link>
                          </Col>
                        )
                    )}
                </Fragment>
              ))}
            {/* auto claim */}
            {/* static menu item */}
            <Col>
              <Link
                className="dropdown-icon-item"
                style={{ width: "140px" }}
                to={claimPaths.autoClaims}
              >
                <i className={`mdi mdi-focus-field font-size-24`}></i>
                <span>Auto Claims</span>
              </Link>
            </Col>
            {/* auto Invoice */}
            {/* static menu item */}
            {/* <Col>
              <Link
                className="dropdown-icon-item"
                style={{ width: "140px" }}
                to={`/invoice-preview`}
              >
                <i className={`bx bx-receipt font-size-24`}></i>
                <span>Invoice Preview</span>
              </Link>
            </Col> */}
          </Row>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ShortcutMenu;

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//i18n
import { withTranslation } from "react-i18next"
import { findDateDifference } from "utils"
import MultiSkeleton from "components/TP/Common/MultiSkeleton"
import { generalPaths } from "common/data/routePaths"
import { useDispatch, useSelector } from "react-redux"
import { getNotifications } from "store/actions"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const dispatch = useDispatch()

  const { notifications, error, loading } = useSelector(state => ({
    notifications: state.Notifications.notifications,
    error: state.Notifications.error,
    loading: state.Notifications.loading,
  }))

  useEffect(() => {
    if (notifications.length === 0) {
      dispatch(getNotifications())
    }
  }, [notifications.length])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          {/* Number of Notifications */}
          {/* <span className="badge bg-danger rounded-pill">1</span> */}
          {notifications.length > 0 ? (
            <span className="badge bg-danger rounded-pill">
              {notifications.length}
            </span>
          ) : null}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Link
                  to={generalPaths.dropdownNotifications}
                  className="small"
                  target="_blank"
                >
                  {props.t("View All")}
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "auto" }}>
            {loading ? <MultiSkeleton length={5} /> : null}
            {!loading &&
              notifications.length > 0 &&
              notifications.map(notification => (
                <Link
                  to=""
                  key={notification.id}
                  className="text-reset notification-item"
                >
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-success rounded-circle font-size-16">
                        <i className="bx bx-badge-check" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">{notification.title}</h6>
                      <div className="font-size-12 text-muted">
                        {/* <p className="mb-1">{notification.description}</p> */}
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          {findDateDifference(notification.created_at)}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to={generalPaths.dropdownNotifications}
              target="_blank"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}

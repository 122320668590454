import React, { Fragment, useMemo, useState } from "react";

//i18n
import { withTranslation } from "react-i18next";

// UI Components
import {
  FormModal,
  ModalSpinner,
  SearchSelect,
} from "../../../components/TP/Common/Forms";

import { Alert } from "reactstrap";

// utility function for error
import { fieldFinder, isInputInvalid } from "utils/";
import { useSelector } from "react-redux";
import { showPredefined } from "helpers/Settings/predefineds/predefineds_herlper";
import {
  claimAdmissionDetailsId,
  claimDetailsModuleId,
} from "utils/modulesIds";
import { getAllUsers } from "helpers/Settings/Users/users_helper";
import { PREDEFINEDS } from "helpers/url_helper";

const ChangeClaimStatusModal = ({
  type,
  validation,
  isOpen,
  setIsOpen,
  OnConfirm,
  isSaving,
}) => {
  // states
  const [error, setError] = useState("");

  // desturcutre validation object
  const { values, setFieldValue, setFieldTouched, errors } = validation;

  const { fields: inputs, loading } = useSelector((state) => ({
    fields: state?.Module?.subModules
      ?.find((item) => item.id === claimDetailsModuleId)
      ?.sub_modules?.find((item) => item.id === claimAdmissionDetailsId)
      ?.fields,
    loading: state?.Module?.loading,
  }));

  const { isRequiredReason } = useMemo(() => {
    const requiredReasonsTypes = [
      "reopen",
      "routed",
      "unprocessed",
      "update-status",
      "void",
      "pending",
    ];
    return {
      isRequiredReason: requiredReasonsTypes.includes(type) ? true : false,
    };
  }, [type]);

  return (
    <FormModal
      show={isOpen}
      onCloseClick={() => setIsOpen(false)}
      onSaveClick={() => {
        OnConfirm();
        setIsOpen(false);
      }}
      modalTitle={
        <span className="text-capitalize">{`${type.replaceAll(
          "-",
          " "
        )} Claim`}</span>
      }
      isSaving={isSaving}
      disableSave={
        (isRequiredReason && !values.change_reason_id) ||
        (type === "routed" && !values.routed_to_user_id)
          ? true
          : false
      }
      disableTooltip={
        isRequiredReason && !values.change_reason_id
          ? "Please Select Reason"
          : ""
      }
    >
      {loading ? (
        <ModalSpinner />
      ) : (
        <form onSubmit={(e) => e.preventDefault()}>
          {/* API Error Message For Re-Insurer Module */}

          {error ? <Alert color="danger">{error}</Alert> : null}

          {inputs && inputs.length > 0 && (
            <Fragment>
              {/* Re-Insurer Name Label & Input  */}
              {type === "forward" && (
                <SearchSelect
                  id="711"
                  name="declined_reason_id"
                  inputField={{
                    ...fieldFinder(inputs, 711),
                    predefined_value: null,
                  }}
                  value={validation.values.declined_reason_id}
                  onChange={validation.setFieldValue}
                  onBlur={validation.setFieldTouched}
                  mappingValue="first_name"
                  mappingLabel="first_name"
                  generateCustomLabels={(item) =>
                    `${item.first_name} ${item.last_name}`
                  }
                  getOptionsData={getAllUsers}
                  urlAddOns={{
                    access_type_id: "1",
                  }}
                  generateDisabledOptions={(option) => option.is_active === 0}
                  invalid={isInputInvalid(validation, "declined_reason_id")}
                  error={validation.errors.declined_reason_id}
                  wrapperClassNames="mb-3"
                  disableViewPage={true}
                />
              )}
              {type === "declined" && (
                <SearchSelect
                  id="711"
                  name="declined_reason_id"
                  inputField={fieldFinder(inputs, 711)}
                  value={validation.values.declined_reason_id || null}
                  onChange={validation.setFieldValue}
                  onBlur={validation.setFieldTouched}
                  mappingLabel="name"
                  mappingValue="id"
                  getOptionsData={showPredefined}
                  recordId={fieldFinder(inputs, 711)?.predefined_value?.id}
                  invalid={isInputInvalid(validation, "declined_reason_id")}
                  error={validation.errors.declined_reason_id}
                  wrapperClassNames="mb-3"
                  disableViewPage={true}
                />
              )}
              {type === "pending" && (
                <SearchSelect
                  id="710"
                  name="pending_reason_id"
                  inputField={fieldFinder(inputs, 710)}
                  value={validation.values.pending_reason_id}
                  onChange={validation.setFieldValue}
                  onBlur={validation.setFieldTouched}
                  mappingLabel="name"
                  mappingValue="id"
                  getOptionsData={showPredefined}
                  recordId={fieldFinder(inputs, 710)?.predefined_value?.id}
                  invalid={isInputInvalid(validation, "pending_reason_id")}
                  error={validation.errors.pending_reason_id}
                  wrapperClassNames="mb-3"
                  disableViewPage={true}
                />
              )}
              {/* When Click unprossed (change status to received) */}
              {type === "unprocessed" && (
                <SearchSelect
                  id="787"
                  name="unprocessed_reason_id"
                  inputField={fieldFinder(inputs, 787)}
                  value={validation.values.unprocessed_reason_id}
                  onChange={validation.setFieldValue}
                  onBlur={validation.setFieldTouched}
                  mappingLabel="name"
                  mappingValue="id"
                  getOptionsData={showPredefined}
                  recordId={fieldFinder(inputs, 787)?.predefined_value?.id}
                  invalid={isInputInvalid(validation, "unprocessed_reason_id")}
                  error={validation.errors.unprocessed_reason_id}
                  wrapperClassNames="mb-3"
                  disableViewPage={true}
                />
              )}

              {/* mark as unused */}
              {type === "void" && (
                <SearchSelect
                  id="711"
                  name="change_reason_id"
                  inputField={{
                    label_name: "Reason Name",
                    is_required: 1,
                  }}
                  value={values.change_reason_id}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  mappingLabel="name"
                  mappingValue="id"
                  url={`${PREDEFINEDS}?parent_slug=claimVoidReason-options`}
                  invalid={isInputInvalid(validation, "change_reason_id")}
                  error={errors.change_reason_id}
                  wrapperClassNames="mb-3"
                  disableViewPage={true}
                />
              )}
            </Fragment>
          )}
        </form>
      )}
    </FormModal>
  );
};

export default withTranslation()(ChangeClaimStatusModal);

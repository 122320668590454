export const claimPaths = {
  claimForm: "/claim-form",
  claimValidation: "/eligibility-verification",
  claimView: "/eligibility-verification-view",
  claimBatch: "/batch-claim",
  reinmbursementClaim: "/reimbursement-claim",
  chronicForm: "/chronic-form",
  claimProcessing: "/claims-processing",
  autoClaims: "/auto-claims",
};

import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { TreeSelect } from "antd";
import FormInputError from "./FormInputError";
import { apiErrorrHandler, NotificationMessage } from "utils";
import DropDownSkeleton from "./DropDownSkeleton";
import { useLocation } from "react-router-dom";
import { compact, defaults, flatten, omit } from "lodash";
import DropdownChangeModal from "../Modals/DropdownChangeModal";
import { BigPagination } from "constants/TPA";
import { generalRequest } from "helpers/General/general_request";
import useSWR from "swr";
import { ModalSpinner } from ".";
import { UncontrolledTooltip } from "reactstrap";

/* 
Send The multiple prop as true to make it multiple 
or (boolean false or not send it) to make single select
*/
const SearchTree = ({
  id,
  name,
  value,
  inputField,
  label: propLabel,
  inputClass: propInputClass,
  labelClass: propLabelClass,
  placeholder: propPlaceholder,
  onChange,
  onBlur,
  invalid,
  error,
  wrapperClassNames,
  treeData: propTreeData,
  multiple,
  fieldNames,
  getTreeData,
  recordId,
  showParent,
  urlQuery,
  dynamicUrlQuery,
  specificKey,
  manualDisable,
  setSelectLabel,
  horizontalLayout,
  customOnChange,
  noLabel,
  labelInValue,
  disableTree,
  readOnly,
  showPopupOnChange,
  onSelectData,
  validateOnSelect,
  onSelectValidateData,
  fetchAsync,
  generateCustomLabels,
  url,
  emptyList,
  disableFetch,
  disableSorting,
  disablePaginate,
  labelPrefix,
  labelSuffix,
  searchAsync,
  searchKey,
  dependency,
  removeSwitcher,
}) => {
  const label =
    inputField && inputField.label_name ? inputField.label_name : propLabel;
  const placeholder =
    inputField && inputField.placeholder
      ? inputField.placeholder
      : propPlaceholder;
  const labelClass = `${
    inputField && inputField.label_class ? inputField.label_class : ""
  } ${propLabelClass ? propLabelClass : ""}`;

  const inputClass = `${
    inputField && inputField.input_class ? inputField.input_class : ""
  } ${propInputClass ? propInputClass : ""}`;

  const { isPredefined, predefinedId } = useMemo(() => {
    return {
      isPredefined: inputField && inputField?.predefined_value ? true : false,
      predefinedId:
        inputField && inputField?.predefined_value
          ? inputField?.predefined_value?.id
          : "",
    };
  }, [inputField]);

  const fetcher = async (url, params) => {
    const isURLHasNum =
      compact(url.split("/").map((item) => +item)).length > 0 ? true : false;
    let additionalParams = isURLHasNum
      ? {}
      : disableSorting
      ? { paginate: BigPagination, view: !disableTree ? "tree" : "" }
      : {
          paginate: BigPagination,
          sort_type: "asc",
          sort_by: fieldNames.label,
          view: !disableTree ? "tree" : "",
        };

    additionalParams = disablePaginate
      ? omit(additionalParams, ["paginate"])
      : additionalParams;
    const res = await generalRequest({
      url,
      ...additionalParams,
      ...params,
    });

    if (res) {
      if (res.items) {
        return specificKey
          ? res.items.map((item) => ({ ...item[specificKey] }))
          : res.items;
      }
      if (res.item) {
        return specificKey ? res.item[specificKey] : res.item;
      }
    }
  };

  const params = defaults(urlQuery, dynamicUrlQuery);
  const {
    isLoading: loading,
    error: err,
    data,
  } = useSWR(
    () => (url && !disableFetch ? [url, params] : null),
    ([url, params]) => fetcher(url, params),
    {
      revalidateOnFocus: false,
    }
  );

  const handleSelect = (selectedValue, node, extra) => {
    console.log("-------onSelect---------");
    console.log("selcted", selectedValue);
    console.log("node", node);
    console.log("exrea", extra);
    // if (validateOnSelect && onSelectData) {
    //   console.log("selected", `focused_field:${name}`)
    //   onSelectData(name, selectedValue)
    // }
    // if (showPopupOnChange) {
    //   setIsDropdownChangeModal(true)
    // }
    if (onSelectData) {
      onSelectData(node);
    }
  };
  const handleChange = (selectedValue, label, extra) => {
    console.log("-------onChange---------");
    console.log(
      "🚀 ~ file: SearchTree.jsx:51 ~ handleChange ~ selectedValue:",
      selectedValue
    );
    console.log("changed label", label);
    console.log("extra", extra);

    if (validateOnSelect && onSelectValidateData) {
      console.log("selected", `focused_field:${name}`);
      onSelectValidateData(name, selectedValue, extra);
    }

    if (setSelectLabel) {
      onChange(
        `${name}`,
        Array.isArray(selectedValue)
          ? selectedValue.map((el) => ({ value: el.value, label: el.label }))
          : selectedValue
          ? { label: selectedValue.label, value: selectedValue.value }
          : ""
      );
      setSelectLabel(selectedValue.label);
    } else {
      onChange(
        `${name}`,
        Array.isArray(selectedValue)
          ? selectedValue.map((el) => el)
          : selectedValue
          ? selectedValue
          : ""
      );
    }
    // setIsOpen(false)
  };
  const handleBlur = () => {
    if (onBlur) {
      onBlur(`${name}`, true);
    }
    // setSearchValue(searchValue)
  };

  const location = useLocation();
  const { pathname } = location;
  // to know if the page link has a view word or not
  const pageType = pathname.split("/")[2];

  const [treeData, setTreeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  // getting the data from the API
  const getData = async (additinonalParams) => {
    setIsLoading(true);
    try {
      let groupRes;
      if (recordId) {
        groupRes = await getTreeData(recordId, urlQuery);
        if (showParent) {
          if (groupRes.items) {
            setTreeData(
              specificKey
                ? groupRes.items.map((item) => ({ ...item[specificKey] }))
                : groupRes.items
            );
          } else {
            setTreeData(
              specificKey ? groupRes.item[specificKey] : groupRes.item
            );
          }
        } else {
          // setTreeData([groupRes.item])
          // setTreeData(groupRes.item?.children)
          setTreeData(isPredefined ? groupRes.item?.children : groupRes.item);
        }
      } else {
        let basicParams = {
          sort_type: "asc",
          sort_by: fieldNames.label,
          ...urlQuery,
          ...dynamicUrlQuery,
          view: !disableTree ? "tree" : "",
          per_page: BigPagination,
          ...additinonalParams,
        };
        basicParams = disablePaginate
          ? omit(basicParams, ["per_page"])
          : basicParams;
        groupRes = await getTreeData(basicParams);
        // setTreeData(groupRes?.items)
        setTreeData(
          specificKey
            ? flatten(groupRes?.items.map((item) => item[specificKey]))
            : groupRes?.items
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    }
  };

  // for fetching on page load
  useEffect(() => {
    if (getTreeData && !fetchAsync && !url && !searchAsync) {
      getData();
    }
  }, [recordId, JSON.stringify(dynamicUrlQuery), fetchAsync, url, searchAsync]);

  // for fetching async
  useEffect(() => {
    if (getTreeData && fetchAsync && isOpen) {
      getData();
    }
  }, [recordId, JSON.stringify(dynamicUrlQuery), fetchAsync, isOpen]);

  useEffect(() => {
    if (url && data) {
      setTreeData(data);
    }
  }, [data, url]);

  useEffect(() => {
    if (err) {
      const errorMessage = apiErrorrHandler(err);
      NotificationMessage("error", errorMessage);
      setTreeData([]);
    }
  }, [err]);

  useEffect(() => {
    if (emptyList) {
      setTreeData([]);
    }
  }, [emptyList]);

  useEffect(() => {
    if (disableFetch) {
      setTreeData([]);
    }
  }, [disableFetch]);

  const handleSearch = async (value) => {
    if (!value) {
      setSearchValue(value);
      return;
    } else {
      setSearchValue(value);
      // getData({ search_keys: "name", search_values: value.trim() })
      if (searchAsync) {
        getData({ search_keys: searchKey, search_value: value.trim() });
      }
    }
  };

  return (
    <div className={`${wrapperClassNames ? wrapperClassNames : ""}`}>
      {/* in some cases we need to not show the label */}
      {!noLabel && (
        <Fragment>
          <label
            htmlFor={id}
            className={`form-label text-capitalize ${
              inputField?.is_required ? "required-label" : ""
            } ${labelClass ? labelClass : ""}`}
            // data-toggle="tooltip"
            // title={placeholder ? placeholder : ""}
            id={`tooltip-${id}`}
          >
            {labelPrefix && labelPrefix}
            {label}
            {labelSuffix && labelSuffix}
          </label>
          {placeholder && (
            <UncontrolledTooltip placement="top" target={`tooltip-${id}`}>
              {placeholder}
            </UncontrolledTooltip>
          )}
          {/* Reload Button */}
          {labelClass.includes("reload-button") ? (
            <button
              className="btn btn-transparent p-0 border-0 m-0 d-inline-block ms-3"
              aria-label="Reload Options"
              type="button"
              onClick={() => getData()}
            >
              <i className="mdi mdi-reload font-size-18"></i>
            </button>
          ) : null}
        </Fragment>
      )}

      <div className={horizontalLayout && inputClass}>
        {isLoading && !fetchAsync && !searchAsync ? (
          <DropDownSkeleton />
        ) : (
          <div
            className={`form-control p-0 ${invalid ? "border-danger" : ""} ${
              inputClass ? inputClass : ""
            } ${pageType === "view" ? "border-0" : ""}`}
          >
            {/* output this componet if search async and is loading */}
            {searchAsync && isLoading ? (
              <TreeSelect
                searchValue={searchValue}
                onSearch={(search) => {
                  handleSearch(search);
                }}
                dropdownRender={(original) => (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "200px" }}
                  >
                    <ModalSpinner />
                  </div>
                )}
                open={true}
                // hide the search in view page
                showSearch={true}
                // hide the arrow in view page
                showArrow={true}
                dropdownStyle={
                  pageType === "view" || readOnly
                    ? { display: "none" }
                    : {
                        maxHeight: 400,
                        overflow: "auto",
                      }
                }
                style={{
                  width: "100%",
                  height: "30px",
                  alignItems: "center",
                }}
                bordered={false}
                // disable clear in view page
                allowClear={true}
                treeData={[]}
                loading={true}
              />
            ) : (
              <TreeSelect
                searchValue={searchValue}
                onSearch={(search) => {
                  // setSearchValue(search)
                  handleSearch(search);
                }}
                disabled={
                  isLoading ||
                  manualDisable ||
                  (pageType === "view" && multiple) ||
                  readOnly
                }
                onDropdownVisibleChange={(open) => {
                  setIsOpen(open);
                }}
                open={isOpen}
                // hide the search in view page
                showSearch={pageType !== "view" && !readOnly}
                // hide the arrow in view page
                showArrow={pageType !== "view" && !readOnly}
                name={name}
                id={id}
                placeholder={placeholder}
                style={{
                  width: "100%",
                  height: multiple && value?.length > 0 ? "auto" : "30px",
                  // height: multiple && value?.length > 1 ? "auto" : "30px",
                  alignItems: "center",
                }}
                bordered={false}
                value={value || null}
                // hide the menu in view page and display it otherwise
                dropdownStyle={
                  pageType === "view" || readOnly
                    ? { display: "none" }
                    : {
                        maxHeight: 400,
                        overflow: "auto",
                      }
                }
                // disable clear in view page
                allowClear={pageType !== "view" && !readOnly}
                multiple={multiple}
                treeNodeFilterProp={fieldNames && fieldNames["label"]}
                onChange={customOnChange ? customOnChange : handleChange}
                onBlur={handleBlur}
                treeData={
                  generateCustomLabels
                    ? generateCustomLabels(treeData)?.map((item) => ({
                        ...item,
                        selectable: item?.disabled ? false : true,
                      }))
                    : propTreeData
                    ? propTreeData
                    : treeData
                }
                labelInValue={setSelectLabel || labelInValue ? true : false}
                fieldNames={fieldNames && fieldNames}
                onSelect={handleSelect}
                loading={(isLoading && fetchAsync) || loading ? true : false}
                popupClassName={removeSwitcher ? "decreaseSwitcherWidth" : ""}
              />
            )}
          </div>
        )}
        {/* Error Message */}
        {invalid && !isLoading && pageType !== "view" && !readOnly ? (
          <FormInputError error={error} selectError={true} />
        ) : null}
      </div>
    </div>
  );
};

export default SearchTree;

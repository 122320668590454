import * as Yup from "yup";
import { fieldFinder } from "./smallUtils";

// fieldsArray => the array that conatin the fields which we pull out from permission level 3 Redux State
// fieldID => the field ID comming from DB
// fieldType => float,integer,string,dropdown,boolean and array

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const generateFieldValidation = (
  fieldsArray,
  fieldId,
  fieldType,
  minmumNum,
  maxNum,
  disableRequired,
  testFunc
) => {
  const field =
    Array.isArray(fieldsArray) &&
    fieldsArray.length > 0 &&
    fieldFinder(fieldsArray, fieldId);

  const isFieldRequired = field && field.is_required;
  const fieldLabelName = field && field.label_name;

  if (fieldType === "string".toLowerCase()) {
    return Yup.string().when([], {
      is: () => isFieldRequired && !disableRequired,
      then: Yup.string().required(`Please Enter ${fieldLabelName}`),
    });
  } else if (fieldType === "date".toLowerCase()) {
    return Yup.string()
      .test(
        testFunc
          ? testFunc
          : () => {
              return true;
            }
      )
      .when([], {
        is: () => isFieldRequired,
        then: Yup.string().required(`Please Select ${fieldLabelName}`),
      });
  } else if (fieldType === "email".toLowerCase()) {
    return Yup.string()
      .email("Must be a valid email")
      .when([], {
        is: () => isFieldRequired,
        then: Yup.string()
          .email("Must be a valid email")
          .required(`Please Select ${fieldLabelName}`),
      });
  } else if (fieldType === "password".toLowerCase()) {
    return Yup.string()
      .min(8, `${fieldLabelName} Must be at Least 8 Characters`)
      .when([], {
        is: () => isFieldRequired,
        then: Yup.string()
          .min(8, `${fieldLabelName} Must be at Least 8 Characters`)
          .required(`Please Select ${fieldLabelName}`),
      });
  } else if (fieldType === "float".toLowerCase()) {
    return Yup.number()
      .min(
        minmumNum ? minmumNum : 0,
        `Must Be ${minmumNum ? minmumNum : "zero"} Or More`
      )
      .test(
        testFunc
          ? testFunc
          : () => {
              return true;
            }
      )
      .when([], {
        is: () => isFieldRequired,
        then: Yup.number()
          .min(
            minmumNum ? minmumNum : 0,
            `Must Be ${minmumNum ? minmumNum : "zero"} Or More`
          )
          .required(`Please Enter ${fieldLabelName}`),
      });
  } else if (fieldType === "integer".toLowerCase()) {
    if (maxNum) {
      return Yup.number()
        .min(
          minmumNum ? minmumNum : 0,
          `Must Be ${minmumNum ? minmumNum : "zero"} Or More`
        )
        .max(maxNum && maxNum, `Must Be equal to or less than ${maxNum}`)
        .integer("Must be integer")
        .test(
          testFunc
            ? testFunc
            : () => {
                return true;
              }
        )
        .when([], {
          is: () => isFieldRequired && !disableRequired,
          then: Yup.number()
            .min(
              minmumNum ? minmumNum : 0,
              `Must Be ${minmumNum ? minmumNum : "zero"} Or More`
            )
            .max(maxNum && maxNum, `Must Be equal to or less than ${maxNum}`)
            .integer("Must be integer")
            .required(`Please Enter ${fieldLabelName}`),
        });
    } else {
      return Yup.number()
        .min(
          minmumNum ? minmumNum : 0,
          `Must Be ${minmumNum ? minmumNum : "zero"} Or More`
        )
        .integer("Must be integer")
        .test(
          testFunc
            ? testFunc
            : () => {
                return true;
              }
        )
        .when([], {
          is: () => isFieldRequired && !disableRequired,
          then: Yup.number()
            .min(
              minmumNum ? minmumNum : 0,
              `Must Be ${minmumNum ? minmumNum : "zero"} Or More`
            )
            .integer("Must be integer")
            .required(`Please Enter ${fieldLabelName}`),
        });
    }
  } else if (fieldType === "dropdown".toLowerCase()) {
    return Yup.string().when([], {
      is: () => isFieldRequired && !disableRequired,
      then: Yup.string().ensure().required(`Please Select ${fieldLabelName}`),
    });
  } else if (fieldType === "boolean".toLowerCase()) {
    return Yup.string().when([], {
      is: () => isFieldRequired,
      then: Yup.string().required(`Please Select ${fieldLabelName}`),
    });
  } else if (fieldType === "editor".toLowerCase()) {
    return Yup.object()
      .test(
        "has text",
        "Cannot save an empty note",
        (value) => value && value.blocks && value.blocks[0].text.length
      )
      .when([], {
        is: () => isFieldRequired && !disableRequired,
        then: Yup.object().required(`Please Enter ${fieldLabelName}`),
      });
  } else if (fieldType === "array".toLowerCase()) {
    return Yup.array().when([], {
      is: () => isFieldRequired && !disableRequired,
      then: Yup.array()
        .min(1, `Please Enter ${fieldLabelName}`)
        .required(`Please Enter ${fieldLabelName}`),
    });
  } else if (fieldType === "phone".toLowerCase()) {
    return Yup.string()
      .matches(
        phoneRegExp,
        `Please enter a valid ${fieldLabelName} number consisting of 10 to 11 digits`
      )
      .min(10, `Must Be at least 10 characters`)
      .max(11, "Must be 11 characters max")
      .when([], {
        is: () => isFieldRequired,
        then: Yup.string()
          .matches(
            phoneRegExp,
            `Please enter a valid ${fieldLabelName} number consisting of 10 to 11 digits`
          )
          .min(10, `Must Be at least 10 characters`)
          .max(11, "Must be 11 characters max")
          .required(`Please Enter ${fieldLabelName}`),
      });
  } else if (fieldType === "object".toLowerCase()) {
    return Yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return Yup.object().when([], {
            is: () => isFieldRequired,
            then: Yup.object().required(`Please Select ${fieldLabelName}`),
          });
        case "string":
          return Yup.string().when([], {
            is: () => isFieldRequired,
            then: Yup.string()
              .ensure()
              .required(`Please Select ${fieldLabelName}`),
          });
        default:
          return Yup.mixed().when([], {
            is: () => isFieldRequired,
            then: Yup.string()
              .ensure()
              .required(`Please Select ${fieldLabelName}`),
          });
      }
    });
  } else {
    return Yup.string().when([], {
      is: () => isFieldRequired,
      then: Yup.string().required(`Please Enter ${fieldLabelName}`),
    });
  }
};

import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD } from "./actionTypes";
import { userForgetPasswordError, userResetPasswordError } from "./actions";

//Include Both Helper File with needed methods
import {
  postForgotPassword,
  postResetPassword,
} from "helpers/AuthType/auth_helper";
import { apiErrorrHandler } from "utils/apiErrorHandler";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(postForgotPassword, {
      username: user.username,
      redirect_link: user.redirect_link,
    });
  } catch (error) {
    const errorMessage = apiErrorrHandler(error);
    yield put(userForgetPasswordError(errorMessage));
    console.log(errorMessage);
  }
}
function* ResetPassword({ payload: { user, history } }) {
  try {
    const response = yield call(postResetPassword, {
      password: user.password,
      new_password: user.new_password,
      new_password_confirmation: user.new_password_confirmation,
      token: user.token,
    });

    console.log(response);
  } catch (error) {
    const errorMessage = apiErrorrHandler(error);
    yield put(userResetPasswordError(errorMessage));
    console.log(errorMessage);
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
  yield takeEvery(RESET_PASSWORD, ResetPassword);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;

import { request } from "helpers/api_helper";
import * as url from "helpers/url_helper";

/*
Claim Forms
*/

// add new Claim

// url
export const addClaim = (data) =>
  request({ url: url.CLAIMFORM, method: "POST", data });

// get all Claim Forms with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllClaims = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  ...rest
}) =>
  request({
    url: url.CLAIMFORM,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      ...rest,
    },
  });

// show Claim
//url/{id}

export const showClaim = (claimFormId) =>
  request({ url: `${url.CLAIMFORM}/${claimFormId}`, method: "GET" });

// update Claim
//url/{id}

export const updateClaim = (data) =>
  request({ url: `${url.CLAIMFORM}/${data.id}`, method: "PUT", data });

// mark unused Claim
//url

export const markUnusedClaim = (data) =>
  request({ url: url.CLAIMFORM, method: "PATCH", data });

// delete Claim
//url/{id}
export const deleteClaim = (claimFormId) =>
  request({ url: `${url.CLAIMFORM}/${claimFormId}`, method: "DELETE" });

// activate Claim
//url/{id}/activate
export const activateClaim = (claimFormId) =>
  request({ url: `${url.CLAIMFORM}/${claimFormId}/activate`, method: "POST" });

// deactivate Claim
//url/{id}/deactivate
export const deactivateClaim = (claimFormId) =>
  request({
    url: `${url.CLAIMFORM}/${claimFormId}/deactivate`,
    method: "POST",
  });

// copy claim
//url/copy/{id}
export const copyClaim = (claimId, data) =>
  request({
    url: `${url.CLAIMFORM}/${claimId}/copy`,
    method: "POST",
    data,
  });

export const getAllExpirySoonClaims = (params) =>
  request({
    url: url.CLAIMEXPIRTYREPORT,
    method: "GET",
    params: params,
  });

// upload unitified prescription
export const uploadUnifiedPrescription = (data) =>
  request({
    url: url.UNIFIEDPRESCRIPTION,
    method: "POST",
    data: data,
  });
// upload unitified prescription
export const uploadAutoClaim = (data) =>
  request({
    url: url.AUTOCLAIM,
    method: "POST",
    data: data,
  });

// Send Email
// body:{
// emails:["email1","email 2"],
//path:"claims/inPatient"
//}
export const sendClaimEmail = (claimId, data) =>
  request({
    url: `${url.CLAIMFORM}/${claimId}/send-email`,
    method: "POST",
    data,
  });

import React, { Fragment, useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { Button, Col, Row, Input } from "reactstrap";

const TablePagination = ({
  pagination,
  getData,
  currentPage,
  setCurrentPage,
  resetInput,
  hideTotalPages = false,

  t,
}) => {
  // destructure the necessary data from pagianation object
  const { page, total_pages } = pagination;
  //   To disable the buttons
  const prevRef = useRef();
  const inputRef = useRef(null);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(true);
  const [perPage, setPerPage] = useState(pagination.per_page);
  prevRef.current = perPage;

  //   decrese the page number by one
  const onPrevPage = () => setCurrentPage(currentPage - 1);
  //   increase the page number by one
  const onNextPage = () => setCurrentPage(currentPage + 1);

  useEffect(() => {
    setPerPage(pagination.per_page);
    setCurrentPage(1);
  }, [pagination.per_page]);

  useEffect(() => {
    if (resetInput) {
      setPerPage(pagination.per_page);
      setCurrentPage(1);
      setCanPreviousPage(false);
      setCanNextPage(true);
    }
  }, [resetInput]);

  // Change the page number based on what number user type in the input
  const onChangeInInput = (event) => {
    const pageNumber = event.target.value ? Number(event.target.value) : 1;
    if (pagination.per_page == prevRef.current) {
      setCurrentPage(pageNumber);
    }
  };

  //   set the states based on the comming data
  useEffect(() => {
    if (total_pages === currentPage) {
      setCanNextPage(false);
    } else {
      setCanNextPage(true);
    }
    if (currentPage === 1) {
      setCanPreviousPage(false);
    } else {
      setCanPreviousPage(true);
    }
  }, [total_pages, currentPage]);

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);
  return (
    <Row className="justify-content-md-end justify-content-center align-items-center py-2">
      <Col className="col-md-auto d-none d-md-inline-block me-auto">
        {hideTotalPages ? null : (
          <Fragment>
            {t("Showing")}
            <strong>
              {" "}
              {pagination.size} {t("of")} {pagination?.total}{" "}
            </strong>
            {t("total")}
          </Fragment>
        )}
      </Col>
      <Col className="col-md-auto">
        <div className="d-flex gap-1">
          {/* go to  first page */}
          <Button
            color="primary"
            onClick={() => setCurrentPage(1)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Button>

          {/* go to  previous page */}
          <Button
            color="primary"
            onClick={onPrevPage}
            disabled={!canPreviousPage}
          >
            {"<"}
          </Button>
        </div>
      </Col>

      {/* to show the user the current page and total pages */}
      <Col className="col-md-auto d-none d-md-block">
        {t("Page")}{" "}
        <strong>
          {page} {t("of")} {total_pages}
        </strong>
      </Col>
      <Col className="col-md-auto">
        <input
          className="form-control"
          id="pageNumber"
          type="number"
          ref={inputRef}
          min={1}
          // onKeyDown={e => e.preventDefault()}
          onKeyDown={(e) => {
            e.key.toLowerCase() !== "arrowup" &&
              e.key.toLowerCase() !== "arrowdown" &&
              e.preventDefault();
          }}
          style={{ width: 70 }}
          max={total_pages}
          //   defaultValue={1}
          value={currentPage}
          onChange={onChangeInInput}
        />
      </Col>

      {/* go to the next page */}
      <Col className="col-md-auto">
        <div className="d-flex gap-1">
          <Button color="primary" onClick={onNextPage} disabled={!canNextPage}>
            {">"}
          </Button>

          {/* go to the last page */}
          <Button
            color="primary"
            onClick={() => setCurrentPage(total_pages)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default withTranslation()(TablePagination);

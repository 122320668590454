import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/UserProfile";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Common Data Modules Pages
import { settingsRoutes } from "./Settings";
import NotFound from "pages/NotFound";
import { ClaimRoutes } from "./Claim";
import { ReportsRoutes } from "./Reports";
import ConfirmMail from "pages/Authentication/ConfirmMail";
import ResetPassword from "pages/Authentication/ResetPassword";
import { claimPaths } from "common/data/routePaths";
// Add Company
import { generalPaths } from "common/data/routePaths";

// import AutoInvoice from "pages/Test/AutoInvoice";

const Tutorial = lazy(() => import("pages/General/Tutorial"));

const { tutorial } = generalPaths;

const authProtectedRoutes = [
  // settings routes
  ...settingsRoutes,
  // claim routes
  ...ClaimRoutes,
  // reports routes,
  ...ReportsRoutes,
  // { path: "/invoice-preview", component: AutoInvoice },
  { path: "/profile", component: UserProfile },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to={claimPaths.claimValidation} />,
  },

  // Tutorial
  { path: tutorial, component: Tutorial },

  // 404 Page
  { path: "*", component: NotFound },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },

  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/confirm-mail", component: ConfirmMail },
  { path: "/new-password", component: ResetPassword },
];
export { authProtectedRoutes, publicRoutes };

import { ADD_DATA, REMOVE_DATA } from "./actionTypes"

const initialState = {
  claimFormData: null,
}

const SharedData = (state = initialState, action) => {
  switch (action.type) {
    // Add Data
    case ADD_DATA:
      state = {
        ...state,
        ...action.payload,
      }
      break
      // case REMOVE_DATA:
      //   state = {
      //     ...state,
      //     isModal: false,
      //     message: "",
      //     title: "",
      //     confirmButtonTitle: "",
      //     closeButtonTitle: "",
      //     includeTwoButtons: false,
      //     confirmCallback: null,
      //     closeCallback: null,
      //   }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SharedData

import * as url from "../../url_helper"
import { request } from "../../api_helper"

/*
Event Manager
*/

// add new Event Manager

// url
export const addEventManager = data =>
  request({ url: url.EVENTMANAGER, method: "POST", data })

// get all Event Managers with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllEvents = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
}) =>
  request({
    url: url.EVENTMANAGER,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
    },
  })

// show Event Manager
//url/{id}

export const showEventManager = eventId =>
  request({ url: `${url.EVENTMANAGER}/${eventId}`, method: "GET" })

// update Event Manager
//url/{id}

export const updateEventManager = data =>
  request({ url: `${url.EVENTMANAGER}/${data.id}`, method: "PUT", data })

// delete Event Manager
//url/{id}
export const deleteEventManager = eventId =>
  request({ url: `${url.EVENTMANAGER}/${eventId}`, method: "DELETE" })

// activate Event Manager
//url/{id}/activate
export const activateEventManager = eventId =>
  request({
    url: `${url.EVENTMANAGER}/${eventId}/activate`,
    method: "POST",
  })

// deactivate Event Manager
//url/{id}/deactivate
export const deactivateEventManager = eventId =>
  request({
    url: `${url.EVENTMANAGER}/${eventId}/deactivate`,
    method: "POST",
  })

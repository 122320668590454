import React, { useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Modal, ModalBody, Spinner } from "reactstrap"
import { NotificationMessage, apiErrorrHandler } from "utils"

const AysncExportCSVModal = ({
  getSheetData,
  queryAddOn,
  isOpen,
  sheetHeader,
  generateCustomData,
  setIsOpen,
  sheetName,
  t,
}) => {
  const { moduleName } = useSelector(state => ({
    moduleName: state.Module.name,
  }))

  const [csvData, setCsvData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getCSVData = async () => {
    setIsLoading(true)
    try {
      const res = await getSheetData({
        page: 1,
        per_page: 1000,
        ...queryAddOn,
      })
      console.log("res", res)
      setCsvData(res.items ? res.items : res.item)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("error", errorMessage)
      console.log("error", errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getCSVData()
  }, [])

  return (
    <Modal
      size="md"
      backdrop="static"
      toggle={setIsOpen}
      isOpen={isOpen}
      centered={true}
    >
      {/* <div className="modal-header pb-2">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {t("Export CSV")}
        </h5>

        <button
          type="button"
          onClick={() => setIsOpen(false)}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
      </div> */}

      <ModalBody>
        {isLoading && csvData.length === 0 ? (
          <div className="d-flex align-items-center gap-4">
            <Spinner className="ms-2" color="info" />
            <h6 className="lh-base">
              Please wait while we Downloading your data.
              <br /> This process may take a few moments.
              <br /> Thank you for your patience.
            </h6>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <CSVLink
              className="btn btn-secondary"
              aria-disabled={isLoading}
              tabIndex={isLoading ? "-1" : "0"}
              data={generateCustomData ? generateCustomData(csvData) : csvData}
              onClick={() => setIsOpen(false)}
              headers={sheetHeader}
              // filename={`${sheetName}.csv`}
              filename={`${
                sheetName ? sheetName : moduleName
              }_${new Date().toISOString()}.csv`}
            >
              {t("Save As")}
            </CSVLink>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(AysncExportCSVModal)

import MultiSkeleton from "components/TP/Common/MultiSkeleton";
import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { buttonsTitles, modalsStates } from "common/data";
import ChatModal from "../Chat/ChatModal";
import WhatssAppModal from "./WhatssAppModal";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import SendEmailModal from "./SendEmailModal";
import ClaimChatNotification from "../Chat/ClaimChatNotification";

const ClaimsBreadcrumbAddons = ({ beneficiary, isLoading, claim }) => {
  const { claimId } = useParams();
  const authUser = JSON.parse(localStorage.getItem("authUser"));

  // store modal states for all modals
  // in this file
  const [isModal, setIsModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  if (isLoading)
    return (
      <div className="bg-white text-danger rounded-1 p-2">
        <MultiSkeleton length={1} />
      </div>
    );

  return (
    !isLoading &&
    beneficiary && (
      <Fragment>
        <div className="d-flex align-items-center">
          <ul className="list-unstyled hstack gap-1  ms-2 flex-wrap">
            {claimId ? (
              <li className="position-relative">
                <button
                  className="badge border-0 bg-primary fw-bold py-2 px-2"
                  onClick={() => setIsModal(modalsStates.chatModal)}
                >
                  {buttonsTitles.chat}
                  {/* Claim Notification Icon */}
                  <ClaimChatNotification claim={claim} inClaimForm={true} />
                </button>
              </li>
            ) : null}

            {/* send wattsapp and Send Email Buttons */}
            {claimId ? (
              <Fragment>
                <Dropdown
                  isOpen={isMenuOpen}
                  toggle={() => setIsMenuOpen(!isMenuOpen)}
                  className="align-self-center"
                  // className="badge border-0 bg-success fw-bold py-2 px-2"
                >
                  <DropdownToggle
                    className="badge border-0 bg-primary fw-bold py-2 px-2"
                    caret
                  >
                    {buttonsTitles.send}
                    <i className="mdi mdi-chevron-down px-1" />
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-end">
                    <button
                      className="dropdown-item d-flex align-content-center gap-3"
                      onClick={() => {
                        setIsModal(modalsStates.whatAppModal);
                        setIsMenuOpen(false);
                      }}
                    >
                      <i className="bx bxl-whatsapp font-size-17" />
                      <span>{buttonsTitles.sendWhatsapp}</span>
                    </button>
                    <button
                      className="dropdown-item d-flex align-content-center gap-3"
                      onClick={() => {
                        setIsModal(modalsStates.emailModal);
                        setIsMenuOpen(false);
                      }}
                    >
                      <i className="bx bx-envelope font-size-16" />
                      <span>{buttonsTitles.sendEmail}</span>
                    </button>
                  </DropdownMenu>
                </Dropdown>
              </Fragment>
            ) : null}
          </ul>
        </div>

        {isModal === modalsStates.chatModal && (
          <ChatModal
            show={isModal === modalsStates.chatModal}
            onCloseClick={() => setIsModal(false)}
            beneficaryId={beneficiary?.id}
            beneficiary={beneficiary}
            claim={claim}
            resourceId={claim?.id}
          />
        )}

        {isModal === modalsStates.whatAppModal && (
          <WhatssAppModal
            isModal={isModal === modalsStates.whatAppModal}
            setIsModal={setIsModal}
            claim={claim}
          />
        )}

        {isModal === modalsStates.emailModal && (
          <SendEmailModal
            isModal={isModal === modalsStates.emailModal}
            setIsModal={setIsModal}
            claim={claim}
          />
        )}
      </Fragment>
    )
  );
};

export default ClaimsBreadcrumbAddons;

import PropTypes from "prop-types";
import React, { Fragment, useMemo, useState } from "react";
import { Modal } from "reactstrap";

// i18n
import { withTranslation } from "react-i18next";
import { TableContainer } from "components/Common";
import moment from "moment";

const StatusHistoryModal = ({ show, onCloseClick, loading, statuses, t }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Status",
        accessor: "status.name",
      },
      {
        Header: "User",
        accessor: "created_by.name",
      },
      {
        Header: "Reason",
        accessor: "reason.name",
      },
      {
        Header: "Date",
        accessor: "created_at",
        // name: "healthcareContract.healthcareProvider.type.name",
        Cell: (cellProps) => {
          return (
            <Fragment>
              {moment(cellProps.row.original?.created_at).format("DD-MM-YYYY")}
            </Fragment>
          );
        },
      },
      {
        Header: "Time",
        accessor: "time",
        // name: "healthcareContract.healthcareProvider.type.name",
        Cell: (cellProps) => {
          return (
            <Fragment>
              {moment(cellProps.row.original?.created_at).format("hh:mm:ss")}
            </Fragment>
          );
        },
      },
    ],
    []
  );

  return (
    <Modal
      size="xl"
      isOpen={show}
      toggle={onCloseClick}
      backdrop="static"
      className="modal-fullscreen"
    >
      <div className="modal-header">
        {/* Modal Header Title */}
        <h5 className="modal-title mt-0" id="myModalLabel">
          Status History
        </h5>

        {/* close button on the top right section */}
        <button
          type="button"
          onClick={onCloseClick}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
      </div>

      {/* Modal Body */}
      <div className="modal-body">
        <TableContainer
          removeActionsColumns={true}
          removeActionsButtons={true}
          columns={columns}
          data={statuses}
          handleAdd={() => {}}
          pagination={null}
          hidePagination={true}
          getData={() => {}}
          loading={loading}
          basicTable={true}
          // for show / hide columns
          //   showOptions={showOptions}
          //   setShowOptions={setShowOptions}
          // fullColumns={generateColumns(actionFields)}
          //   sheetName="Network Providers"
          //   customCSVHeader={csvHeader}
          // basicTable={true}
          // deleteFunc={deleteNetworkHealthCare}
          // activateFunc={activateNetworkHealthCare}
          // deactivateFunc={deactivateNetworkHealthCare}
          // handleClickUpdate={handleClickUpdate}
          //   moduleId={subModule?.id}
          //   subModuleId={networkProvidersModuleId}
        />
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={onCloseClick}
        >
          {t("Close")}
        </button>
      </div>
    </Modal>
  );
};

StatusHistoryModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
  isSaving: PropTypes.bool,
  children: PropTypes.any,
  // modalTitle: PropTypes.string,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default withTranslation()(StatusHistoryModal);

import React, { useState, useEffect, useRef, Fragment } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  CardTitle,
  InputGroup,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar.png"

import { getProfile, updateProfile } from "helpers/AuthType/auth_helper"
import { apiErrorrHandler, isInputInvalid, NotificationMessage } from "utils"
import { ModalSpinner } from "components/TP/Common/Forms"
import { UploadFiles } from "utils/API/UploadFiles"
import { useScrollWhenError } from "hooks"

const UserProfile = props => {
  //meta title
  document.title = "Update Profile"
  const [showPassword, setShowPassword] = useState(false)

  const [profile, setProfile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [imageId, setImageId] = useState(null)
  const [error, setError] = useState("")

  const uploadImage = async () => {
    const fileIds = await UploadFiles([image])
    setImageId(fileIds[0])
    setImage(null)
  }

  const getData = async () => {
    setIsLoading(true)
    try {
      const response = await getProfile()
      setProfile(response.item)
      console.log(response.item)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  const handleUpdateProfile = async (values, setSubmitting) => {
    try {
      const response = await updateProfile({
        ...values,
        photo_id: imageId ? imageId : profile?.photo?.id,
      })
      NotificationMessage("Success", "Profile Updated Successfully")
      localStorage.setItem("authUser", JSON.stringify(response.item))
      setError("")
      setImageId(null)
      getData()
      // history.push("/")
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      setError(errorMessage)
    } finally {
      setSubmitting(false)
    }
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: profile ? profile.first_name : "",
      last_name: profile ? profile.last_name : "",
      phone: profile ? profile.phone : "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      phone: Yup.string().required("Please Enter Your Phone Number"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleUpdateProfile(values, setSubmitting)
    },
  })

  const handleChangePassword = async (values, setSubmitting, resetForm) => {
    try {
      const response = await updateProfile({
        password: values.password,
      })
      console.log(response)
      NotificationMessage("Success", "Password Changed Successfully")
      resetForm()
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("error", errorMessage)
    } finally {
      setSubmitting(false)
    }
  }
  const passwordValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      handleChangePassword(values, setSubmitting, resetForm)
    },
  })

  useScrollWhenError(error)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Skote" breadcrumbItem="Profile" />

          {isLoading ? (
            <ModalSpinner />
          ) : (
            <Fragment>
              <Row>
                <Col lg="12">
                  {error && error ? (
                    <Alert color="danger">{error}</Alert>
                  ) : null}
                  {/* {success ? <Alert color="success">{success}</Alert> : null} */}

                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="ms-3">
                          <img
                            src={profile?.photo?.path || avatar}
                            alt=""
                            className="avatar-md rounded-circle img-thumbnail object-fit-cover"
                          />
                        </div>
                        <div className="flex-grow-1 align-self-center">
                          <div className="text-muted">
                            <h5>
                              {profile
                                ? profile?.first_name + " " + profile?.last_name
                                : ""}
                            </h5>
                            <p className="mb-1">
                              {profile ? profile.email : ""}
                            </p>
                            <p className="mb-0">
                              {profile ? profile?.job_title?.name : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* <h4 className="card-title mb-4">Update Profile Data</h4> */}

              <Row>
                <Col xl={6}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Main Details</CardTitle>
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <Row className="form-group mb-4">
                          <Label className="col-sm-3 col-form-label">
                            First Name
                          </Label>
                          <Col sm={9}>
                            <Input
                              name="first_name"
                              className="form-control"
                              placeholder="Enter First Name"
                              type="text"
                              {...validation.getFieldProps("first_name")}
                              invalid={isInputInvalid(validation, "first_name")}
                            />
                            {isInputInvalid(validation, "first_name") ? (
                              <FormFeedback type="invalid">
                                {validation.errors.first_name}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>

                        <Row className="form-group mb-4">
                          <Label className="col-sm-3 col-form-label">
                            Last Name
                          </Label>
                          <Col sm={9}>
                            <Input
                              name="last_name"
                              className="form-control"
                              placeholder="Enter Last Name"
                              type="text"
                              {...validation.getFieldProps("last_name")}
                              invalid={isInputInvalid(validation, "last_name")}
                            />
                            {isInputInvalid(validation, "last_name") ? (
                              <FormFeedback type="invalid">
                                {validation.errors.last_name}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>

                        <Row className="form-group mb-4">
                          <Label className="col-sm-3 col-form-label">
                            Mobile Number
                          </Label>
                          <Col sm={9}>
                            <Input
                              name="phone"
                              className="form-control"
                              placeholder="Enter Phone Number"
                              type="text"
                              {...validation.getFieldProps("phone")}
                              invalid={isInputInvalid(validation, "phone")}
                            />
                            {isInputInvalid(validation, "phone") ? (
                              <FormFeedback type="invalid">
                                {validation.errors.phone}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>

                        {/* Profile Image Input */}
                        <Row>
                          <div className="input-group">
                            <Input
                              type="file"
                              className="form-control"
                              id="inputGroupFile04"
                              aria-describedby="inputGroupFileAddon04"
                              aria-label="Upload"
                              accept="image/*"
                              onChange={e => setImage(e.target.files[0])}
                            />
                            <button
                              className="btn btn-primary"
                              type="button"
                              id="inputGroupFileAddon04"
                              onClick={uploadImage}
                            >
                              Upload
                            </button>
                          </div>
                        </Row>

                        <div className="text-left mt-4">
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                            disabled={validation.isSubmitting}
                          >
                            Save
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl={6}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Password</CardTitle>

                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          passwordValidation.handleSubmit()
                          return false
                        }}
                      >
                        <Row className="form-group mb-4 position-relative">
                          <Label className="col-sm-3 col-form-label">
                            New Password
                          </Label>
                          <Col sm={9}>
                            <InputGroup>
                              <Input
                                name="password"
                                className="form-control"
                                placeholder="Enter New Password"
                                type={showPassword ? "text" : "password"}
                                {...passwordValidation.getFieldProps(
                                  "password"
                                )}
                                invalid={isInputInvalid(
                                  passwordValidation,
                                  "password"
                                )}
                              />

                              <div
                                className="input-group-text"
                                onClick={() =>
                                  setShowPassword(showPassword => !showPassword)
                                }
                              >
                                {showPassword ? (
                                  <i className="mdi mdi-eye-off-outline font-size-14"></i>
                                ) : (
                                  <i className="mdi mdi-eye-outline font-size-14"></i>
                                )}
                              </div>
                            </InputGroup>

                            {/* <Input
                              name="password"
                              className="form-control"
                              placeholder="Enter New Password"
                              type={showPassword ? "text" : "password"}
                              {...passwordValidation.getFieldProps("password")}
                              invalid={isInputInvalid(
                                passwordValidation,
                                "password"
                              )}
                            /> */}
                            {/* Show Password Icon */}
                            {/* 
                            {passwordValidation.values.password.length > 0 ? (
                              <span
                                className="showPasswordIconContainer"
                                style={{
                                  right: "2.5%",
                                  transform: "translateY(-72%)",
                                }}
                                onClick={() =>
                                  setShowPassword(showPassword => !showPassword)
                                }
                              >
                                <i className="mdi mdi-eye-outline font-size-18"></i>
                              </span>
                            ) : null} */}
                            {isInputInvalid(passwordValidation, "password") ? (
                              <FormFeedback type="invalid">
                                {passwordValidation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>

                        <div className="text-left mt-4">
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                            disabled={passwordValidation.isSubmitting}
                          >
                            Save
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Fragment>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)

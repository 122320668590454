import React, { Fragment } from "react";
import parse from "html-react-parser";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
const RightBox = ({ isLoading, claim }) => {
  return (
    <PerfectScrollbar id="sticky-sidebox">
      <div className="bg-primary px-3 py-4" style={{ minHeight: "100%" }}>
        {!isLoading && claim && (
          <Fragment>
            {/* Remaing limit */}

            {/* Beneficiary notes */}
            <Fragment>
              <span className="fw-bold text-white text-uppercase d-block my-1 font-size-14">
                Approval Notes
              </span>
              <span className="text-white font-size-12 fw-light">
                {parse(claim?.authorization_notes)}
              </span>
            </Fragment>
          </Fragment>
        )}
      </div>
    </PerfectScrollbar>
  );
};

export default RightBox;

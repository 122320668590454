/*
    this hook is to get the page type
    page types available now 
    add , edit , view , processing
*/
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
const usePageType = () => {
  const location = useLocation()
  const pathname = location.pathname
  const [pageType, setPageType] = useState(undefined)
  const [isAddPage, setIsAddPage] = useState(false)
  const [isEditPage, setIsEditPage] = useState(false)
  const [isViewPage, setIsViewPage] = useState(false)
  const [isProcessingPage, setIsProcessingPage] = useState(false)
  //   const isAddPage = pageType?.toLowerCase() === "add" ? true : false
  //   const isEditPage = pageType?.toLowerCase() === "edit" ? true : false
  //   const isViewPage = pageType?.toLowerCase() === "view" ? true : false
  //   const isProcessingPage =
  //     pageType?.toLowerCase() === "processing" ? true : false

  useEffect(() => {
    const type = pathname.split("/")[2]
    if (type) {
      setPageType(type)
      if (type.toLowerCase() === "add") setIsAddPage(true)
      if (type.toLowerCase() === "edit") setIsEditPage(true)
      if (type.toLowerCase() === "view") setIsViewPage(true)
      if (type.toLowerCase() === "processing") setIsProcessingPage(true)
    } else {
      setPageType(undefined)
      setIsAddPage(false)
      setIsEditPage(false)
      setIsViewPage(false)
      setIsProcessingPage(false)
    }
  }, [pathname])

  return { isAddPage, isEditPage, isViewPage, isProcessingPage, pageType }
}

export default usePageType
